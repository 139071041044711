import React, { Component } from "react";
import moment from "moment";
import AdminHeader from "../../_components/AdminHeader";
import Loader from "../../_helpers/Loader";
import PopupModal from "../../_helpers/PopupModal";
import Logout from "../../_helpers/Logout";
import { admindashboardservice } from "../../_services/admindashboard.service";
import AdminFooter from "../../_components/AdminFooter";
import { history } from "../../_helpers/history";
import { MessageType } from "../../_helpers/Enums";
import { adminservice } from "../../_services/admin.service";
import DataTable from "react-data-table-component";
import AddEditTemplate from "./Components/AddEditTemplate";
import { helperText } from "../../_helpers/helpertext";
import { GetLocalStorageDataWithoutParse, GetLocalStorageDataWithParse, AddLocalStorageData } from "../../_helpers/utilities";


const dateFormat = "YYYY-MM-DD";

//S.S 25 May 2023. Added +1 to Month state as new Date().getMonth() returns the month (from 0 to 11) of a date
export default class AdminNotification extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            showPopupModal: false,
            popUpModalText: "",
            messageType: 0,
            userID: 0,
            messageSent: [],
            unfilteredMessageSent: [],
            messageReceived: [],
            unfilteredMessageReceived: [],
            notificationCount: 0,
            templateList: [],
            showAddEditTemplate: false,
            templateName: "",
            templateSubject: "",
            templateMessage: "",
            templateFileName: "",
            courseEnquiryEmailTemplateId: 0,
            checkedBoxes: [],
            errorMessage: {},
            recievedSearchValue: "",
            sentSearchValue: "",
            yearReceived: new Date().getFullYear(),
            monthReceived: new Date().getMonth() + 1,
            yearSent: new Date().getFullYear(),
            monthSent: new Date().getMonth() + 1,
            yearListReceived: [],
            yearListSent: [],
            activeTab: 1,
            page: 1, // Initial page number
            isScrolling: false, // Flag to indicate if data is being loaded
            tabclicked: 1,
        };
        this.loadMoreData = this.loadMoreData.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
    }

    /* <summary>
    date: 15-11-2021
    Name: VD
    description: This is used to get admin notitfication that have been sent and recived by the admin
    <summary>*/
    componentDidMount = () => {
        this.createYearItems();
        this.getNotificationDatafromserver(this.state.yearReceived, this.state.monthReceived, this.state.page);
        window.addEventListener('scroll', this.handleScroll);
    };

    componentWillUnmount() {
        // Remove event listener when component unmounts
        window.removeEventListener('scroll', this.handleScroll);
    };

    getNotificationDatafromserver = async (year, month, page) => {
        try {
            this.setState({
                isLoading: true,
            });
            var response = await admindashboardservice.GetAdminNotification(year, month, page);
            if (response.status == 200) {
                this.setState(prevState => ({
                    messageSent: [...prevState.messageSent, ...response.data.fromResponse],
                    unfilteredMessageSent: [...prevState.unfilteredMessageSent, ...response.data.fromResponse],
                    messageReceived: [...prevState.messageReceived, ...response.data.toResponse],
                    unfilteredMessageReceived: [...prevState.unfilteredMessageReceived, ...response.data.toResponse],
                    isScrolling: false
                }));
                //this.setState({
                //    messageSent: response.data.fromResponse,
                //    unfilteredMessageSent:response.data.fromResponse,
                //    messageReceived: response.data.toResponse,
                //    unfilteredMessageReceived:response.data.toResponse,
                //    notificationCount: GetLocalStorageDataWithoutParse("notificationCount")
                //});
            } else if (response.status == 401) {
                Logout();
            } else {
                this.setState({
                    popUpModalText: helperText.DataLoadError.loadError,
                    showPopupModal: true,
                    messageType: MessageType.Failure,
                });
            }

            this.getEmailTemplates();

            this.setState({
                isLoading: false,
            });
        } catch (e) {
            return "";
        }
    };

    loadMoreData() {
        // Update page number and load more data
        this.setState(prevState => ({
            page: prevState.page + 1,
            isScrolling: true
        }), () => {
            this.getNotificationDatafromserver(this.state.yearReceived, this.state.monthReceived, this.state.page);
        });
    }

    handleScroll() {
        // Check if user has scrolled to the bottom of the page
        if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
            // Load more data if not already loading
            if (!this.state.isScrolling) {
                this.loadMoreData();
            }
        }
    }

    getEmailTemplates = async () => {
        this.setState({ isLoading: true });

        var response = await adminservice.GetEmailTemplate();
        if (response.status == 200) {
            this.setState({
                templateList: response.data,
            });
        }

        this.setState({ isLoading: false });
    };

    closeModal = () => {
        this.setState({
            showPopupModal: false,
        });
    };

    openEmail = (emailId, type) => {
        const roleId = GetLocalStorageDataWithoutParse("RoleID");
        localStorage.setItem("MessageType", type);
        history.push(`/ReadEmail/${emailId}/${roleId}`);
    };

    header = [
        {
            name: (
                <th className="text-center material-tab" scope="col">
                    Name
                </th>
            ),
            //selector: "Title",
            cell: (row) => <td className="center material-tab">{row.templateName}</td>,
            center: true,
        },
        {
            name: (
                <th className="text-center material-tab" scope="col">
                    Subject
                </th>
            ),
            //selector: "Title",
            cell: (row) => <td className="center material-tab">{row.emailSubject}</td>,
            center: true,
        },
        {
            name: (
                <th className="text-center material-tab" scope="col">
                    Updated On
                </th>
            ),
            //selector: "Title",
            cell: (row) => <td className="center material-tab">{moment(row.editedOn).format(dateFormat)}</td>,
            center: true,
        },
        {
            name: (
                <th className="text-center material-tab" scope="col">
                    Action
                </th>
            ),
            //selector: "Title",
            cell: (row) => (
                <td
                    className="center material-tab"
                    onClick={() => this.ViewTemplate(row)}
                    style={{ cursor: 'pointer' }}
                >
                    <a>
                        Edit
                    </a>
                </td>
            ),
            center: true,
        },
    ];

    ViewTemplate = (row) => {
        if (row == 0) {
            this.setState({
                templateName: "",
                templateSubject: "",
                templateMessage: "",
                templateFileName: "",
                showAddEditTemplate: true,
                courseEnquiryEmailTemplateId: 0,
            });
        } else {
            this.setState({
                templateName: row.templateName,
                templateSubject: row.emailSubject,
                templateMessage: row.emailMessage,
                templateFileName: row.attachmentileMediaName,
                showAddEditTemplate: true,
                courseEnquiryEmailTemplateId: row.courseEnquiryEmailTemplateID,
            });
        }
    };

    closeTemplatePage = (value) => {
        this.setState({
            showAddEditTemplate: false,
        })
        if (value == 1) {
            this.setState({
                popUpModalText: helperText.AdminNotification.templateSaved,
                showPopupModal: true,
                messageType: MessageType.Success,
            })
            this.getEmailTemplates();
        }
    }

    onMonthReceivedChange = (event) => {
        var selectedmonth = event.target.value
        this.setState({
            monthReceived: selectedmonth,
            messageReceived: [],
            unfilteredMessageReceived: [],
        }, () => { this.getNotificationDatafromserver(this.state.yearReceived, this.state.monthReceived, this.state.page) });
    }

    onYearReceivedChange = (event) => {
        var selectedyear = event.target.value

        this.setState({
            yearReceived: selectedyear,
            messageReceived: [],
            unfilteredMessageReceived: [],
        }, () => { this.getNotificationDatafromserver(this.state.yearReceived, this.state.monthReceived, this.state.page) });
    }

    onMonthSentChange = (event) => {
        var selectedmonth = event.target.value
        this.setState({
            monthSent: selectedmonth,
            messageSent: [],
            unfilteredMessageSent: [],
        }, () => { this.getNotificationDatafromserver(this.state.yearSent, this.state.monthSent, this.state.page) });
    }

    onYearSentChange = (event) => {
        var selectedyear = event.target.value
        this.setState({
            yearSent: selectedyear,
            messageSent: [],
            unfilteredMessageSent: [],
        }, () => { this.getNotificationDatafromserver(this.state.yearSent, this.state.monthSent, this.state.page) });
    }

    handleChange = (state) => {
        // You can use setState or dispatch with something like Redux so we can use the retrieved data
        this.setState({
            checkedBoxes: state.selectedRows,
            isChanged: true,
            errorMessage: {},
        });
    };

    deleteSelectedTemplate = async () => {
        try {
            this.setState({
                isLoading: true,
            });
            const { checkedBoxes } = this.state;

            if (this.validateCheckbox()) {
                var response = await adminservice.DeleteCourseEnquiryTemplates(checkedBoxes);
                if (response.status == 200) {
                    this.setState({
                        checkedBoxes: [],
                        popUpModalText: helperText.AdminNotification.templateDeleted,
                        showPopupModal: true,
                        messageType: MessageType.Success,
                    });
                    this.getEmailTemplates();
                } else {
                    this.setState({
                        popUpModalText: helperText.AdminNotification.templateDeleteError,
                        showPopupModal: true,
                        messageType: MessageType.Failure,
                    });
                }
            }
            this.setState({
                isLoading: false,
            });
        } catch (e) {
            return "";
        }
    };

    validateCheckbox = () => {
        const { checkedBoxes } = this.state;
        try {
            var isValid = true;
            if (checkedBoxes == "") isValid = false;

            if (checkedBoxes == "") {
                var error = this.state.errorMessage;
                error["emptyCheckbox"] = helperText.createCourse.emptyCourseCheckbox;
                this.setState({ errorMessage: error });
            }
            return isValid;
        } catch (error) { }
    };

    onReceivedSearchTextChange = (event) => {
        var inputValue = event.target.value.toLowerCase();

        var filteredReceivedMessage = this.state.unfilteredMessageReceived.filter(
            (p) =>
                p.recievedFrom.toLowerCase().includes(inputValue) ||
                p.subject.toLowerCase().includes(inputValue)
        );
        this.setState({
            recievedSearchValue: event.target.value,
            messageReceived: filteredReceivedMessage
        });
    }

    onsentSearchTextChange = (event) => {
        var inputValue = event.target.value.toLowerCase();

        var filteredReceivedMessage = this.state.unfilteredMessageSent.filter(
            (p) =>
                p.recievedTo.toLowerCase().includes(inputValue) ||
                p.subject.toLowerCase().includes(inputValue)
        );
        this.setState({
            sentSearchValue: event.target.value,
            messageSent: filteredReceivedMessage
        });
    }

    createYearItems() {
        let items = [];
        let minyear = 2021;
        let maxyear = new Date().getFullYear();
        for (let i = minyear; i <= maxyear; i++) {
            items.push(i);
        }
        this.setState({ yearListReceived: items });
        this.setState({ yearListSent: items });
    }

    tabclicked(tab) {
        if (tab == 1) {
            this.setState({ sentSearchValue: "", tabclicked: 1 }, () => {
                //this.getNotificationDatafromserver(this.state.yearReceived, this.state.monthReceived, this.state.page);
            });
        }
        if (tab == 2) {
            this.setState({ recievedSearchValue: "", tabclicked: 2 }, () => {
                //this.getNotificationDatafromserver(this.state.yearSent, this.state.monthSent, this.state.page);
            });
        }
        if (tab == 3) {
            this.setState({ recievedSearchValue: "", sentSearchValue: "", tabclicked: 3 }, () => { });
        }
        this.setState({ activeTab: tab });
    }

    render() {
        const {
            isLoading,
            popUpModalText,
            messageType,
            showPopupModal,
            messageSent,
            messageReceived,
            notificationCount,
            unfilteredMessageReceived,
            unfilteredMessageSent,
            templateList,
            showAddEditTemplate,
            courseEnquiryEmailTemplateId,
            templateName,
            templateSubject,
            templateMessage,
            templateFileName,
            recievedSearchValue,
            sentSearchValue,
            activeTab,
            tabclicked,
        } = this.state;
        return (
            <div id="admin" style={{ backgroundColor: "#F1F4F6" }}>
                <AdminHeader currentTab="None" />
                {isLoading && <Loader />}
                <section className="admin-main-body ">
                    <section
                        id="admincoursenbanner"
                        style={{ marginTop: "-21px" }}
                        className="notification-banner"
                    >
                        <div className="row">
                            <div className="col-md-12">
                                <h2 style={{ marginTop: "3px" }}>
                                    Messages -{" "}
                                    <small>
                                        {" "}
                                        Unread {notificationCount} (of {tabclicked == 1 ? unfilteredMessageReceived.length : unfilteredMessageSent.length})
                                    </small>
                                    &nbsp;&nbsp;
                                    {/* <button type="button" className="btn btn-primary">
                        <img src="images/new-message.png" />
                        &nbsp;New Message
                      </button>{" "} */}
                                </h2>
                            </div>
                        </div>
                    </section>
                    <section
                        className="courselist col-md-12 manage-notification-section"
                        id="content"
                    >
                        <ul className="nav nav-tabs m-b-n-xxs xd">
                            <li class={tabclicked == 1 ? "active notifiaction-btn-msg" : "notifiaction-btn-msg"}
                                onClick={() => this.tabclicked(1)}>
                                {" "}
                                <a className="msg-link" >
                                    {" "}
                                    Received
                                </a>{" "}
                            </li>
                            <li class={tabclicked == 2 ? "active notifiaction-btn-msg sent-sm" : "notifiaction-btn-msg sent-sm"}
                                style={{ marginLeft: "7px" }}
                                onClick={() => this.tabclicked(2)}
                            >
                                {" "}
                                <a className="msg-link" >
                                    Sent
                                </a>{" "}
                            </li>
                            <li class={tabclicked == 3 ? "active notifiaction-btn-msg" : "notifiaction-btn-msg"}
                                style={{ marginLeft: "7px" }}
                                onClick={() => this.tabclicked(3)}
                            >
                                {" "}
                                <a className="msg-link" >
                                    {" "}
                                    Manage Message Template
                                </a>{" "}
                            </li>
                        </ul>
                        <div className="panel panel-default tab-content xdff manage-tap-notify-div">
                            {tabclicked == 1 &&
                                <ul className="list-group tab-pane active" id="Recieved">
                                    <div className="search-container" style={{ paddingBottom: "8px" }}>
                                        <div className="row">
                                            <div className="col-md-2">
                                                <span style={{ marginLeft: '15px' }}> Year : </span>
                                                <select
                                                    className="form-control1 courses"
                                                    id="year"
                                                    name="year"
                                                    value={this.state.yearReceived}
                                                    onChange={e => this.onYearReceivedChange(e)}
                                                    style={{
                                                        marginLeft: "15px",
                                                        width: "100%",
                                                    }}
                                                >
                                                    {this.state.yearListReceived.map((item) => {
                                                        return (
                                                            <option value={item}>{item}</option>
                                                        );
                                                    })}
                                                </select>
                                            </div>

                                            <div className="col-md-2">
                                                <span style={{ marginLeft: '15px' }}>Month :</span>
                                                <select
                                                    className="form-control1 courses"
                                                    id="month"
                                                    name="month"
                                                    value={this.state.monthReceived}
                                                    onChange={e => this.onMonthReceivedChange(e)}
                                                    style={{
                                                        marginLeft: "15px",
                                                        width: "100%",
                                                    }}
                                                >
                                                    <option value={1}>January</option>
                                                    <option value={2}>February</option>
                                                    <option value={3}>March</option>
                                                    <option value={4}>April</option>
                                                    <option value={5}>May</option>
                                                    <option value={6}>June</option>
                                                    <option value={7}>July</option>
                                                    <option value={8}>August</option>
                                                    <option value={9}>September</option>
                                                    <option value={10}>October</option>
                                                    <option value={11}>November</option>
                                                    <option value={12}>December</option>
                                                </select>
                                            </div>
                                            <div className="col-md-8">
                                                <form className="example1" style={{ margin: "17px auto 0", float: "right", marginRight: "15px", width: "100%" }}>

                                                    <input
                                                        type="text"
                                                        placeholder="Search.."
                                                        name="recievedSearchValue"
                                                        value={recievedSearchValue}
                                                        onChange={(e) => this.onReceivedSearchTextChange(e)}
                                                    />
                                                    <button>
                                                        <i className="fa fa-search" />
                                                    </button>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                    {messageReceived.map((item) => {
                                        return (
                                            <li className="list-group-item">
                                                <h4 style={{ fontSize: "15px" }}>
                                                    from {item.recievedFrom} -
                                                    {moment(item.dateRecieved).format(
                                                        "DD/MM/YYYY - HH:mm:ss "
                                                    )}{" "}
                                                </h4>
                                                <ul className="mail-items">
                                                    {item.viewed ? (
                                                        ""
                                                    ) : (
                                                        <li>
                                                            <img src="images/new.png" />
                                                        </li>
                                                    )}
                                                    <li>
                                                        <img src="images/reply-message.png" />
                                                    </li>
                                                </ul>
                                                {item.viewed ? (
                                                    <p onClick={() => this.openEmail(item.notificationId, "receivedMail")} style={{ cursor: 'pointer' }}>
                                                        {item.subject}
                                                    </p>
                                                ) : (
                                                    <p onClick={() => this.openEmail(item.notificationId, "receivedMail")} style={{ cursor: 'pointer' }}>
                                                        <strong>{item.subject}</strong>
                                                    </p>
                                                )}
                                            </li>
                                        );
                                    })}
                                </ul>
                            }
                            {tabclicked == 2 &&
                                //    <ul
                                //    className="list-group tab-pane list-group-alt list-group-lg"
                                //    id="Sent"
                                //>
                                <div className="search-container" style={{ paddingBottom: "8px" }}>
                                    <div className="row">
                                        <div className="col-md-2">
                                            <span style={{ marginLeft: '15px' }}> Year : </span>
                                            <select
                                                className="form-control1 courses"
                                                id="year"
                                                name="year"
                                                value={this.state.yearSent}
                                                onChange={e => this.onYearSentChange(e)}
                                                style={{
                                                    marginLeft: "15px",
                                                    width: "100%",
                                                }}
                                            >
                                                {this.state.yearListSent.map((item) => {
                                                    return (
                                                        <option value={item}>{item}</option>
                                                    );
                                                })}
                                            </select>
                                        </div>

                                        <div className="col-md-2">
                                            <span style={{ marginLeft: '15px' }}>Month :</span>
                                            <select
                                                className="form-control1 courses"
                                                id="month"
                                                name="month"
                                                value={this.state.monthSent}
                                                onChange={e => this.onMonthSentChange(e)}
                                                style={{
                                                    marginLeft: "15px",
                                                    width: "100%",
                                                }}
                                            >
                                                <option value={1}>January</option>
                                                <option value={2}>February</option>
                                                <option value={3}>March</option>
                                                <option value={4}>April</option>
                                                <option value={5}>May</option>
                                                <option value={6}>June</option>
                                                <option value={7}>July</option>
                                                <option value={8}>August</option>
                                                <option value={9}>September</option>
                                                <option value={10}>October</option>
                                                <option value={11}>November</option>
                                                <option value={12}>December</option>
                                            </select>
                                        </div>

                                        <div className="col-md-8">
                                            <form className="example1" style={{ margin: "17px auto 0", float: "right", marginRight: "15px", width: "100%" }}>
                                                <input
                                                    type="text"
                                                    placeholder="Search.."
                                                    name="sentSearchValue"
                                                    value={sentSearchValue}
                                                    onChange={(e) => this.onsentSearchTextChange(e)}
                                                />
                                                <button>
                                                    <i className="fa fa-search" />
                                                </button>
                                            </form>
                                        </div>
                                    </div>
                                    {messageSent.map((item) => {
                                        return (
                                            <li className="list-group-item">
                                                <h4 style={{ fontSize: "15px" }}>
                                                    to {item.recievedTo} -
                                                    {moment(item.dateSent).format("DD/MM/YYYY - HH:mm:ss")}{" "}
                                                </h4>
                                                <ul className="mail-items">
                                                    <li>
                                                        <img src="images/reply-message.png" />
                                                    </li>
                                                </ul>
                                                <p onClick={() => this.openEmail(item.notificationId, "sentMail")} style={{ cursor: 'pointer' }}>
                                                    {item.subject}
                                                </p>
                                            </li>
                                        );
                                    })}
                                </div>
                                //</ul>
                            }
                            {tabclicked == 3 &&
                                //    <ul
                                //    className="list-group tab-pane list-group-alt list-group-lg"
                                //    id="Manage"
                                //>
                                <div className="manage-tap-notification">
                                    {showAddEditTemplate ? (
                                        <AddEditTemplate
                                            CancelTemplate={this.closeTemplatePage}
                                            courseEnquiryEmailTemplateId={courseEnquiryEmailTemplateId}
                                            templateName={templateName}
                                            templateSubject={templateSubject}
                                            templateMessage={templateMessage}
                                            templateFileName={templateFileName}
                                        />
                                    ) : (
                                        <div className="row">
                                            <div className=" col-12 col-md-12">
                                                <h5 className="mt-3 mb-3 gray">Template</h5>
                                            </div>
                                            <div
                                                className=" col-12 col-md-12"
                                                style={{ paddingTop: "20px", paddingBottom: '10px' }}
                                            >
                                                <button type="button" className="btn delete-button" onClick={() => this.deleteSelectedTemplate()}>
                                                    <img
                                                        src="images/Delete icon.png"
                                                        className="delete-icon"
                                                        width="20px"
                                                    />{" "}
                                                    Delete
                                                </button>
                                                <button
                                                    type="button"
                                                    className="btn btn-dark button-color create-template"
                                                    onClick={() => this.ViewTemplate(0)}
                                                >
                                                    Create Template
                                                </button>
                                                <div
                                                    style={{
                                                        display: this.state.errorMessage.emptyCheckbox
                                                            ? "table-cell"
                                                            : "none",
                                                        color: "red",
                                                    }}
                                                >
                                                    {this.state.errorMessage.emptyCheckbox}
                                                </div>
                                            </div>

                                            <div className="col-md-12">

                                                <DataTable
                                                    columns={this.header}
                                                    data={templateList}
                                                    noHeader={true}
                                                    pagination
                                                    //customTheme={stripedDocumentDataTable}
                                                    //selectableRows
                                                    onSelectedRowsChange={this.handleChange}
                                                    selectableRows // add for checkbox selection
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>
                                //</ul>
                            }
                        </div>
                    </section>
                    <div className="clearfix" />
                </section>
                <AdminFooter />
                {showPopupModal && (
                    <PopupModal
                        closeModal={this.closeModal}
                        Text={popUpModalText}
                        messageType={messageType}
                    />
                )}
            </div>
        );
    }
}
