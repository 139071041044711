import webserviceWrapper from "../_helpers/webserviceWrapper";

export const admindashboardservice={
    GetDashboardData,
    GetAdminNotification,
    GetAutonotification,
    AddEditAutoNotification,
}

function GetDashboardData(){
    return webserviceWrapper.get(
        `Dashboard/GetDashboardData`
    );
}

function GetAdminNotification(year, month, page) {
    return webserviceWrapper.post(
        `Admin/GetAdminNotification`,
        { year, month, page },
        60000
    )
}

function GetAutonotification(){
    return webserviceWrapper.get(
        `Admin/GetAutonotification`
    )
}

function AddEditAutoNotification(feesPaymentReminder, feesPaymentDays, feesPaymentMonths, offerHelpBeforeCourseCompletionReminder, offerHelpBeforeCourseCompletionDays, offerHelpBeforeCourseCompletionMonths, advanceCourseOptInNotification, assignmentNotification, courseExpirationReminder, courseExpirationDays, courseExpirationMonths) {
    return webserviceWrapper.post(
        `Admin/AddEditAutoNotification`,
        { feesPaymentReminder, feesPaymentDays, feesPaymentMonths, offerHelpBeforeCourseCompletionReminder, offerHelpBeforeCourseCompletionDays, offerHelpBeforeCourseCompletionMonths, advanceCourseOptInNotification, assignmentNotification, courseExpirationReminder, courseExpirationDays, courseExpirationMonths },
        60000
    )
}
